// Font weights
$extra-bold: 800;
$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;

$sans: 'Cutive Mono', Helvetica, Arial, sans-serif;

h1, %h1 {
  font-size: 2.25rem;
  font-weight: $light;
  letter-spacing: 0.5px;
  margin-bottom: 2.5rem;
}

h2, %h2 {
  font-size: 1rem;
  font-weight: $medium;
  letter-spacing: 4px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}

h3, %h3 {
  font-size: 1.5rem;
  letter-spacing: 0.5px;
}

h4, %h4 {
  font-size: 1.1rem;
  letter-spacing: 0.5px;
}

h5, %h5 {
  font-size: 0.75rem;
  text-transform: uppercase;
}

p, .p {
  font-size: 1.25rem;
}

strong {
  font-weight: $medium;
}

@media screen and (max-width: $phone-landscape-max) {
  h1, %h1 {
    font-size: 1.75rem;
  }

  h4, %h4 {
    font-size: 1.5rem;
  }
}
