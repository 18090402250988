$content-width: 58.75rem;

.content {
  @include clearfix;
  margin: 0 auto;
  max-width: $content-width;
  padding: 2rem;
  width: 90%;
}

@media screen and (max-width: $phone-landscape-max) {
  .content {
    width: 100%;
  }

  section {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
